* {
  margin: 0;
}

body {
  font-family: 'Lato', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.intro1 {
  padding: 1rem;
  text-align: left;
  font-size: 1.4rem;
}

.searchText {
  text-align: left;
  font-size: 0.8rem;
  padding: 0.4rem 0;
  font-weight: 600;
}

.footerStore {
  padding: 1rem 0;
}

.artLeft {
  width: 50rem;
}

.artRight {
  width: 35rem;
}

@media screen and (max-width: 823px) {
  .footerStore {
    padding: 1rem 2rem 0;
  }

  .artLeft {
    width: 26rem;
  }

  .artRight {
    width: 20rem;
  }
}

/* Home Page Intro Two WeDo */
.wedo__info {
  display: grid;
  grid-template-rows: 100px 1fr;
}

.wedo__title {
  font-weight: 800;
  font-size: 22px;
  line-height: 21px;
  color: #194376;
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.wedo__lists {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 34px;
  margin-top: 3em;
}

.wedo__listsStarIcon {
  display: none;
}

.wedo__list {
  text-align: center;
  gap: 13px;
  display: grid;
  grid-auto-rows: 1fr 1fr;
  justify-items: center;
  align-items: start;
  gap: 13px;
}

.wedo__listIcon {
  height: 89px;
  width: 89px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
}

.wedo__listDes {
  font-size: 16px;
  line-height: 25px;
  color: #194376;
  width: 80%;
  margin: 0 auto;
}

.wedo__example {
  margin-top: 4em;
  display: grid;
  grid-template-rows: 100px 1fr;
}

.wedo__exampleDes {
  font-weight: 800;
  font-size: 13px;
  line-height: 23px;
  color: #6d8fb4;
}

.wedo__exampleImg {
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  height: auto;
}

/* Home Page SubFooter Tmols */

.tmol__container {
  padding: 40px 24px;
}

.tmol__lists {
  margin-top: 1.5rem !important;
}

.tmol__list__wrapper {
  padding-top: 50px;
}

.tmol__list {
  border-radius: 0.75rem;
  background: #eee;
  padding: 80px 29px 29px 18px;
  position: relative;
}

.tmol__listProfile {
  position: absolute;
  top: -12%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  height: 85px;
  width: 85px;
  border: 3px solid var(--primary-color-yellow);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tmol__listProfileImg {
  height: 72px;
  width: 72px;
}

.tmol__listDes {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.7rem;
  text-align: justify;
  color: var(--primary-color-navy-light);
  max-height: 192px;
  overflow-y: auto;
}

.tmol__listBottom {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
}

.tmol__author {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--primary-color-navy);
}

.tmol__author span {
  opacity: 0.5;
}

.tmol__listDots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin-top: 32px;
}

.tmol__listDot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: var(--primary-color-yellow);
}

/* ---------------------------------- offer --------------------------------- */
.offer__container {
  padding: 32px;
  background-color: #ffb81f;
  position: relative;
}

.offer__bgTriangle {
  position: absolute;
  border-top: 200px solid #ffc340;
  border-left: 200px solid transparent;
  right: 0;
  top: 0;
  bottom: 0;
}

.offer__info {
  position: relative;
}

.offer__des {
  font-size: 36px;
  font-weight: 900;
  color: var(--white-color);
  text-align: left;
}

.offer__des span {
  color: var(--primary-color-navy);
}

.offer__btn {
  margin-top: 23px;
  background-color: var(--primary-color-navy-light);
  padding: 25px 50px;
  color: var(--white-color);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.3px;
}

@media (min-width: 62em) {

  /* wedo */
  .wedo__title {
    text-align: start;
    margin: unset;
    font-weight: 800;
    font-size: 36px;
    line-height: 32px;
    width: 100%;
  }

  .wedo__lists {
    gap: 13px;
    position: relative;
    margin-top: unset;
  }

  .wedo__list {
    background-color: var(--white-color);
    width: 100%;
    height: 100%;
    align-items: flex-start;
    padding: 28px 10px 28px 28px;
  }

  .wedo__listIcon {
    width: unset;
    height: unset;
  }

  .wedo__listIcon img {
    height: 40px;
    width: 40px;
  }

  .wedo__listDes {
    margin: unset;
    text-align: start;
    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
  }

  .wedo__example {
    margin-top: unset;
    height: 100%;
  }

  .wedo__exampleDes {
    width: 80%;
    max-height: 46px;
    text-align: left;
  }

  .wedo__exampleImg {
    margin-top: unset;
    height: 100%;
    object-fit: cover;
  }

  .wedo__listsStarIcon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* tmol */
  .tmol__container {
    margin: 0 auto;
    padding-right: unset;
    padding-left: 8em;
    padding-top: 2.5rem;
    padding-bottom: 0px;
  }

  .tmol__list__wrapper.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .tmol__list__wrapper {
    max-width: 509px;
    padding-top: unset;
    cursor: grab;
    padding-left: 30px;
    padding-bottom: 50px;
  }

  .tmol__list__wrapper:active {
    cursor: grabbing;
  }

  .tmol__list__wrapper.swiper-slide {
    padding-left: 30px;
    padding-bottom: 50px;
    /* background-color: var(--primary-color-blue); */
  }

  /* .tmol__list__wrapper.swiper-slide-active {
      padding-left: 30px;
      background-color: var(--primary-color-blue);
  }
  .tmol__list__wrapper.swiper-slide-next {
      padding-left: 60px;
      background-color: var(--primary-color-blue);
  } */

  .tmol__list {
    padding: 29px 29px 29px 80px;
    max-width: 509px;
  }

  .tmol__listProfile {
    top: 30%;
    left: -6%;
    transform: translateY(-50%);
    transform: translateZ(50%);
  }

  /* 
  .tmol__listDots {
    display: none;
  } */

  /* ---------------------------------- offer --------------------------------- */

  .offer {
    background-color: var(--white-color);
  }

  .offer__container {
    padding: 62px 71px;
    margin: 0 auto;
    gap: 3em;
    position: relative;
  }

  .offer__bgTriangle {
    border-top: 200px solid #ffc340;
    border-left: 400px solid transparent;
  }

  .offer__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .offer__btn {
    white-space: nowrap;
    margin-top: unset;
  }
}

/* utils */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.navy-text {
  color: var(--primary-color-navy);
}

.navy-light-text {
  color: var(--primary-color-navy-light);
}
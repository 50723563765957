.cookie-container {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background: #2f3640;
  color: #f5f6fa;
  padding: 8px 32px;
  font-size: 13px;
  box-shadow: 0 -2px 16px rgba(47, 54, 64, 0.39);

  transition: 400ms;
  z-index: 99999999;
}

.cookie-btn {
  background-color: #488214;
  border: 0;
  color: #f5f6fa;
  padding: 5px 15px;
  font-size: 14px;
  margin: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.closed {
  background-color: #ff0000;
}

@media only screen and (max-width: 600px) {
  .cookie-container {
    display: block;
    text-align: center;
  }
}

.menuIcon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 8rem;
}

.cartIcon {
  background-color: black !important;
  color: white !important;
  margin-left: 25rem;
}

/* .col2row1 {
  margin-right: 10rem !important;
} */

.col2Icons {
  padding-right: 0.8rem;
}

.col2IconsP {
  text-align: left;
}

.placeOrderButton {
  width: 32.6rem;
  background-color: #3c64b1;
  color: white;
}

.postalCodeAdd {
  width: 35rem;
}

.contactName {
  justify-content: flex-start;
}

.nameField {
  width: 16.5rem;
  /* pointer-events: none;*/
}
.paymentNameField {
  width: 10rem;

  /* pointer-events: none;*/
}
.secCodeField {
  width: 12.5rem;
}

.secCodeGrid {
  justify-content: flex-start;
}

.collectHead {
  text-align: left;
}

.collectField {
  justify-content: flex-start;
}

.collectGrid {
  justify-content: flex-start;
}

.collectDate {
  width: 9rem;
}

.collectTime {
  width: 10rem;
}

.collectHow {
  width: 14rem;
}

.paymentRadio {
  margin-left: 0.75rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.errorMessage {
  font-size: 11px;
  color: red;
}

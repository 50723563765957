* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;

  --primary-color-blue: #46c6ce;
  --primary-color-yellow: #edd242;
  --primary-color-navy: #143a69;
  --primary-color-navy-light: #194376;
  --secondary-color-blue: #a2d1d7;
  --secondary-color-navy: #6d8fb4;
  --secondary-color-gray: #c4c4c4;
  --white-color: #fff;
  --background-color: #f3f7fa;
  --black-color: #000000;
}

[data-amplify-authenticator] {
  --amplify-components-authenticator-form-padding: 10px
    var(--amplify-space-medium) 10px;
  --amplify-components-flex-gap: 0.5rem;
}
